<template>
  <validation-observer ref="simpleRules">
    <div>
      <loader
        v-if="ShowProduct"
        object="#009bc2"
        color1="#ffffff"
        color2="#1763fd"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        disable-scrolling="false"
        name="circular"
      />
      <!-- <this-header /> -->
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>
          <div class="col-10">
            <h3>Anan wallet</h3>

            <div class="dashboard mt-1">
              <div class="dashboard-profile">
                <div class="dashboard-mod-title">
                  ข้อมูลส่วนตัว <span>|</span>
                  <a
                    data-spm="dprofile_edit"
                    href="#/profile/edit"
                  >แก้ไข</a>
                </div>
                <div class="dashboard-info">
                  <div class="dashboard-info-item">
                    คุณลมโชย วงสวาด
                  </div>
                  <div class="dashboard-info-item">
                    lomcho***@gmail.com
                  </div>
                  <div class="dashboard-info-item dashboard-info-item-check">
                    <div class="dashboard-info-item-check-sub my-50">
                      <b-form-checkbox
                        v-model="selected"
                        value="A"
                      >
                        <small>รับการแจ้งเตือน ผ่านทางอีเมล</small>
                      </b-form-checkbox>
                    </div>

                    <div class="dashboard-info-item-check-sub">
                      <b-form-checkbox
                        v-model="selected"
                        value="A"
                      >
                        <small>รับการแจ้งเตือนทาง ผ่านทาง Line</small>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-address">
                <div class="dashboard-address-item shipping">
                  <div class="dashboard-mod-title">
                    สมุดที่อยู่ <span>|</span>
                    <a
                      href="/address#/book"
                      data-spm="dprofile_edit"
                    >แก้ไข</a>
                  </div>
                  <div class="dashboard-address-default">
                    ที่อยู่เริ่มต้นในการจัดส่งสินค้า
                  </div>
                  <div class="dashboard-address-username">
                    คุณลมโชย วงสวาด
                  </div>
                  <div class="dashboard-address-detail">
                    12/345 */ ม.10 ศุภาลัย วิลล์ วงแหวน-รัตนาธิเบต ต.บางแม่นาง
                  </div>
                  <div class="dashboard-address-detail">
                    นนทบุรี/ Nonthaburi - บางใหญ่/ Bang Yai - 11140
                  </div>
                  <div class="dashboard-address-phone">
                    (+66) 0659356829
                  </div>
                </div>
                <div class="dashboard-address-item billing">
                  <div class="dashboard-mod-title">
                    สมุดที่อยู่ <span>|</span>
                    <a
                      data-spm="dprofile_edit"
                      href="/address#/book"
                    >แก้ไข</a>
                  </div>
                  <div class="dashboard-address-default">
                    ที่อยู่เริ่มต้นในการออกใบกำกับภาษี
                  </div>
                  <div class="dashboard-address-username">
                    คุณลมโชย วงสวาด
                  </div>
                  <div class="dashboard-address-detail">
                    12/345 ม.10 ศุภาลัย วิลล์ วงแหวน-รัตนาธิเบต ต.บางแม่นาง
                  </div>
                  <div class="dashboard-address-detail">
                    นนทบุรี/ Nonthaburi - บางใหญ่/ Bang Yai - 11140
                  </div>
                  <div class="dashboard-address-phone">
                    (+66) 0659356829
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="add-addr"
      title="เพิ่มที่อยู่ใหม่"
      ok-title="บันทึก"
      cancel-title="ยกเลิก"
      @ok.prevent="addAddr()"
    >
      <validation-observer ref="addRules">
        <b-form-group
          label-for="h-name"
          label="ชื่อ-นามสกุล"
        >
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <b-form-input
              id="h-name"
              v-model="fullName"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-phone-number"
          label="หมายเลขโทรศัพท์"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone Number"
            rules="required"
          >
            <b-form-input
              id="h-phone-number"
              v-model="phoneNumber"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-address"
          label="ที่อยู่/เลขที่"
        >
          <validation-provider
            #default="{ errors }"
            name="Address"
            rules="required"
          >
            <b-form-textarea
              id="h-address"
              v-model="address"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-province-name"
          label="จังหวัด"
        >
          <validation-provider
            #default="{ errors }"
            name="Province"
            rules="required"
          >
            <b-form-input
              id="h-province-name"
              v-model="province"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name"
          label="อำเภอ/เขต"
        >
          <validation-provider
            #default="{ errors }"
            name="District"
            rules="required"
          >
            <b-form-input
              id="h-district-name"
              v-model="district"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name"
          label="ตำบล/แขวง"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <b-form-input
              id="h-sub-district-name"
              v-model="subDistrict"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code"
          label="รหัสไปรษณีย์"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code"
            rules="required"
          >
            <b-form-input
              id="h-postal-code"
              v-model="postalCode"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import SideBar from './component/SideBar.vue'

export default {
  name: 'CustomerNewView',
  components: {
    // ThisHeader,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BModal,
    BFormCheckbox,
    SideBar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      name: '',
      category: '',
      code: '',
      details: '',
      selectedFiles: [],
      uploadedImages: [],
      fieldsCar: [
        { key: 'car', label: 'รถยนต์', thStyle: { width: '50%' } },
        { key: 'kg', label: 'กิโล.', thStyle: { width: '25%' } },
        { key: 'queue', label: 'คิว.', thStyle: { width: '25%' } },
      ],
      fieldsShip: [
        { key: 'ship', label: 'เรือ', thStyle: { width: '50%' } },
        { key: 'kg', label: 'กิโล.', thStyle: { width: '25%' } },
        { key: 'queue', label: 'คิว.', thStyle: { width: '25%' } },
      ],
      itemsCar: [
        { car: 'รถยนต์ 4 ล้อ', kg: '1,000', queue: '1' },
        { car: 'รถยนต์ 6 ล้อ', kg: '1,500', queue: '2' },
        { car: 'รถยนต์ 10 ล้อ', kg: '2,000', queue: '3' },
      ],
      itemsShip: [
        { ship: 'เรือ 4 ล้อ', kg: '1,000', queue: '1' },
        { ship: 'เรือ 6 ล้อ', kg: '1,500', queue: '2' },
        { ship: 'เรือ 10 ล้อ', kg: '2,000', queue: '3' },
      ],
      role: [
        { name: 'ผู้ดูแล - รายการสั่งซื้อ', status: true },
        { name: 'ผู้ดูแล - ผู้ใช้งาน', status: false },
        { name: 'ผู้ดูแล - สิทธิ์การเข้าใช้งาน', status: false },
        { name: 'ผู้ดูแล - กลุ่มผู้ใช้งาน', status: false },
        { name: 'ผู้ดูแล - รหัสพัสดุ', status: false },
        { name: 'ผู้ดูแล - เติมเงินเข้าระบบ', status: false },
        { name: 'ผู้ดูแล - ถอนเงินออกจากระบบ', status: false },
        { name: 'ผู้ดูแล - สมุดบัญชี', status: false },
        { name: 'ผู้ดูแล - ใบเสร็จ', status: false },
        { name: 'ผู้ดูแล - ฝากจ่ายเถาเป่า', status: false },
        { name: 'ผู้ดูแล - ร้านค้าคืนเงิน', status: false },
        { name: 'ผู้ดูแล - เติมอาลีเพย์', status: false },
        { name: 'ผู้ดูแล - โอนเงินไปจีน', status: false },
        { name: 'ผู้ดูแล - สมุดเงินหยวน', status: false },
        { name: 'ผู้ดูแล - แลกหยวนเป็นบาท', status: false },
        { name: 'ผู้ดูแล - ชำระค่านำเข้า', status: false },
        { name: 'ผู้ดูแล - รายการส่งออกสินค้า', status: false },
        { name: 'ผู้ดูแล - สร้างใบชำระให้ลูกค้า', status: false },
        { name: 'ผู้ดูแล - แจ้งสินค้าไม่ได้', status: false },
        { name: 'ผู้ดูแล - สินค้าไม่มีเจ้าของ', status: false },
        { name: 'ผู้ดูแล - ล็อตสินค้า', status: false },
        { name: 'ผู้ดูแล - รับสินค้าเข้าไทย', status: false },
        { name: 'ผู้ดูแล - ข้อมูลโกดัง', status: false },
        { name: 'ผู้ดูแล - รายการแต้ม', status: false },
        { name: 'ผู้ดูแล - แลกแต้ม', status: false },
        { name: 'ผู้ดูแล - รายการสินค้า', status: false },
        { name: 'ผู้ดูแล - สต๊อคคงเหลือ', status: false },
        { name: 'ผู้ดูแล - ประเภทสินค้า', status: false },
        { name: 'ผู้ดูแล - ประเภทการจัดส่ง', status: false },
        { name: 'ผู้ดูแล - บัญชีธนาคาร', status: false },
        { name: 'ผู้ดูแล - ทั่วไป', status: false },
        { name: 'ผู้ดูแล - โปรโมชั่น', status: false },
        { name: 'ผู้ดูแล - ส่งของไปจีน', status: false },
        { name: 'ผู้ดูแล - ประกาศ', status: false },
        { name: 'ผู้ดูแล - รายงาน', status: false },
        { name: 'ผู้ดูแล - ส่วนลดล็อตสินค้า', status: false },
        { name: 'ผู้ดูแล - ประเภทการจัดส่ง(รถ/เรือ)', status: false },
        { name: 'ผู้ดูแล - คำถามที่พบบ่อย', status: false },
        { name: 'ผู้ดูแล - คูปอง', status: false },
        { name: 'ผู้ดูแล - รวมรหัสพัสดุ', status: false },
        { name: 'ผู้ดูแล - แก้ไขเรทรายการสั่งซื้อ', status: false },
        { name: 'ผู้ดูแล - ปลดล็อคถอนเงิน', status: false },
        { name: 'ผู้ดูแล - ระบบ QC', status: false },
        { name: 'ผู้ดูแล - ปิดงานโกดัง', status: false },
        { name: 'ผู้ดูแล - บทความ', status: false },
        { name: 'ผู้ดูแล - พนักงาน', status: false },
        { name: 'สมาชิก - ผู้ใช้งาน', status: true },
        { name: 'สมาชิก - รหัสพัสดุ', status: true },
        { name: 'รายการสั่งซื้อ', status: true },
        { name: 'เติมเงิน', status: true },
        { name: 'ชำระค่านำเข้า', status: true },
        { name: 'สมุดบัญชี', status: true },
        { name: 'รหัสพัสดุ', status: true },
        { name: 'อาลีเพย์', status: true },
        { name: 'โอนเงินไปจีน', status: true },
        { name: 'แจ้งไม่ได้รับสินค้า', status: true },
        { name: 'ฝากจ่ายเถาเป่า', status: true },
        { name: 'ร้านค้าคืนเงิน', status: true },
        { name: 'สมุดบัญชีเงินหยวน', status: true },
        { name: 'แต้ม', status: true },
        { name: 'สินค้าไม่มีเจ้าของ', status: true },
        { name: 'ANAN MALL', status: true },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getPreProduct()
  },
  methods: {
    async getPreProduct() {
      const { data } = await this.$http.get('/v1/product/pre-product')
      this.info = data
      this.ShowProduct = false
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showAddAddr() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.$bvModal.show('modal-1')
    },
  },
}
</script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
